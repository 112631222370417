@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto", sans-serif;
  }
}
.bg{
  background-color: #2b3478;
}
.focus-border:focus {
  outline-color: #2b3478 !important; 
  color: #2b3478 !important; 
}
.scrollbar-none::-webkit-scrollbar{
  display: none !important;
}

/* .bg-blue-theme{

}
.bg-theme{
  
} */
